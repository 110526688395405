import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Deposition materials`}</h4>
    <p>{`Deposition materials include materials such as sputtering targets, which are used in physical vapor deposition. In this process, argon ions are fired at the target, whose atoms are stripped off and deposited as a thin film on the wafer. Other deposition materials can be used to form interconnects, which are wires that connect devices within a chip, or for deposition of insulators that form layers within transistors and also between interconnects.`}</p>
    <p>{`Many other materials used in deposition are simply purified versions of raw materials such as aluminum, copper, tantalum, and titanium.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      